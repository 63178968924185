<div class="container">
  <div class="content">
    <div *ngIf="showAgeConsent()" class="age-consent">
      <app-age-consent (close)="closeDialog()"></app-age-consent>
    </div>

    <router-outlet></router-outlet>
  </div>
  <div class="bottom-tab">
    <div class="menu-items">
      <a
      class="url"
      [routerLink]="'/'"
      routerLinkActive="selected"
      [routerLinkActiveOptions]="{ exact: true }"
    >
    <mat-icon>home</mat-icon>
      <label>{{ "app.home" | transloco }}</label>
    </a>
    <a
      class="url"
      [routerLink]="'favorites'"
      routerLinkActive="selected"
      [routerLinkActiveOptions]="{ exact: true }"
    >
    <svg xmlns="http://www.w3.org/2000/svg" fill="#838383" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-path-fav">
          <rect id="Rectangle_24" data-name="Rectangle 24" width="24" height="24" transform="translate(264 683)" stroke="#707070" stroke-width="1"/>
        </clipPath>
      </defs>
      <g id="Mask_Group_9" data-name="Mask Group 9" transform="translate(-264 -683)" clip-path="url(#clip-path)">
        <g id="padlock_secure_locked_love_heart_shaped" data-name="padlock,secure,locked,love,heart shaped" transform="translate(260.94 679.941)">
          <circle id="Ellipse_2" data-name="Ellipse 2" cx="0.941" cy="0.941" r="0.941" transform="translate(14.119 16.237)"/>
          <path id="Path_17" data-name="Path 17" d="M20.943,10.359V8.942a5.883,5.883,0,0,0-11.766,0v1.417a6.079,6.079,0,0,0-3.23,5.365c0,5.7,8.382,11.007,8.739,11.23a.717.717,0,0,0,.749,0c.357-.223,8.739-5.535,8.739-11.23A6.079,6.079,0,0,0,20.943,10.359Zm-5.177,9.052v.826a.706.706,0,1,1-1.412,0v-.826a2.353,2.353,0,1,1,1.412,0Zm-.706-8.946a5.993,5.993,0,0,0-4.471-.64V8.942a4.471,4.471,0,1,1,8.942,0v.884a5.993,5.993,0,0,0-4.471.64Z"/>
        </g>
      </g>
    </svg>
    
      <label>{{ "app.favourites" | transloco }}</label>
    </a>
      <a href="escorte" class="adventures-button" [routerLink]="'escorte'"
      routerLinkActive="selected"
      [routerLinkActiveOptions]="{ exact: true }">
        <button mat-fab
        
        ><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="36.118" height="37.688" viewBox="0 0 36.118 37.688">
          <defs>
            <clipPath id="clip-path">
              <rect id="Rectangle_16" data-name="Rectangle 16" width="36.118" height="37.688" transform="translate(264.75 680.022)" stroke="#707070" stroke-width="1"/>
            </clipPath>
          </defs>
          <g id="Mask_Group_8" data-name="Mask Group 8" transform="translate(-264.75 -680.022)" clip-path="url(#clip-path)">
            <g id="love_heart_research_valentine_search_healthcare_romance" data-name="love,heart,research,valentine,search,healthcare,romance" transform="translate(264.75 682.708)">
              <path id="Path_15" data-name="Path 15" d="M31.778,20.87a8.278,8.278,0,0,1,5.269,1.888,15.329,15.329,0,0,0,2.1-7.549A10.973,10.973,0,0,0,21.085,6.831,10.973,10.973,0,0,0,3.026,15.209c0,12.487,16.935,20.567,17.655,20.9a.95.95,0,0,0,.808,0A40.753,40.753,0,0,0,25.1,34.128,8.3,8.3,0,0,1,31.778,20.87ZM11.11,10.245a5.775,5.775,0,0,0-2.856,4.964.95.95,0,0,1-1.9,0A7.685,7.685,0,0,1,10.15,8.6a.951.951,0,0,1,.96,1.641Z" transform="translate(-3.026 -4.237)"/>
              <path id="Path_16" data-name="Path 16" d="M28.817,26.263a6.44,6.44,0,1,0-1.344,1.344l1.936,1.936A.95.95,0,1,0,30.753,28.2Z" transform="translate(5.086 2.495)"/>
            </g>
          </g>
        </svg>
        </button>
        <label>{{ "app.escorts" | transloco }}</label>
      </a>
        <a
          class="url"
          [href]="navigateService.url + '/account'"
        >
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21" height="21.913" viewBox="0 0 21 21.913">
          <defs>
            <clipPath id="clip-path-cont">
              <rect id="Rectangle_23" data-name="Rectangle 23" width="21" height="21.913" transform="translate(328 680.022)" fill="#838383" stroke="#707070" stroke-width="1"/>
            </clipPath>
          </defs>
          <g id="Mask_Group_6" data-name="Mask Group 6" transform="translate(-328 -680.022)" clip-path-cont="url(#clip-path)">
            <g id="Icons" transform="translate(328 680.478)">
              <g id="Group_6" data-name="Group 6">
                <path id="Path_11" data-name="Path 11" d="M21,22.225V21.088a7.021,7.021,0,0,0-7-7H7a7.021,7.021,0,0,0-7,7v1.137Z" transform="translate(0 -1.225)" fill="#838383"/>
                <path id="Path_12" data-name="Path 12" d="M15.7,4.638a4.638,4.638,0,1,1-9.275,0,4.638,4.638,0,1,1,9.275,0Z" transform="translate(-0.558)" fill="#838383"/>
              </g>
            </g>
          </g>
        </svg>
        
          <label>{{ "app.account" | transloco }}</label>
        </a>
        <div
          mat-button
          [matMenuTriggerFor]="menu"
          class="url"
        >
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20.406" height="21.294" viewBox="0 0 20.406 21.294">
          <defs>
            <clipPath id="clip-path-menu">
              <rect id="Rectangle_13" data-name="Rectangle 13" width="20.406" height="21.294" transform="translate(42.75 680.022)" fill="#838383" stroke="#707070" stroke-width="1"/>
            </clipPath>
          </defs>
          <g id="Mask_Group_1" data-name="Mask Group 1" transform="translate(-42.75 -680.022)" clip-path-menu="url(#clip-path)">
            <g id="menu" transform="translate(42.75 680.465)">
              <rect id="Rectangle_17" data-name="Rectangle 17" width="9.523" height="9.523" rx="3.067" transform="translate(0 0)" fill="#838383"/>
              <rect id="Rectangle_18" data-name="Rectangle 18" width="9.523" height="9.523" rx="3.067" transform="translate(10.883 0)" fill="#838383"/>
              <rect id="Rectangle_19" data-name="Rectangle 19" width="9.523" height="9.523" rx="3.067" transform="translate(0 10.883)" fill="#838383"/>
              <rect id="Rectangle_20" data-name="Rectangle 20" width="9.523" height="9.523" rx="3.067" transform="translate(10.883 10.883)" fill="#838383"/>
            </g>
          </g>
        </svg>
        
        
          <label>{{ "app.more" | transloco }}</label>
        </div>
        <mat-menu #menu="matMenu">
          <ng-template matMenuContent>
          @for (item of appExtraPages; track item) {
          <a
          class="menu-extra-item"
            mat-menu-item
            [routerLink]="item.url"
            routerLinkActive="selected"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <mat-icon>{{ item.icon }}</mat-icon>
            <label>{{ "app." + item.title | transloco }}</label>
          </a>
          }
        </ng-template>
        </mat-menu>
      </div>
  </div>
</div>

<ng-template #customLoadingTemplate>
  <div class="custom-loading-class">
    <img src="../assets/images/loading2.gif" />
  </div>
</ng-template>
<ngx-loading
  [show]="loaderService.getLoading() === true"
  [template]="customLoadingTemplate"
></ngx-loading>
