<img class="logo" src="/assets/images/logo-white.png" />
<h1>{{ "app.welcome" | transloco }}</h1>
<p>{{ "app.age-consent-description" | transloco }}</p>
<div class="buttons ion-margin-top">
  
  <button
  mat-stroked-button
    href="https://www.google.com"
  >
    {{ "app.leaveSite" | transloco }}
  </button>
  <button mat-raised-button (click)="dismiss()">
    {{ "app.continue" | transloco }}
  </button>
</div>
<p>{{ "app.age-consent-publicity" | transloco }}</p>
<p>{{ "app.age-consent-escorts" | transloco }}</p>
<p>{{ "app.age-consent-actions" | transloco }}</p>
