import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  TransferState,
  WritableSignal,
  makeStateKey,
  signal,
} from '@angular/core';
import {
  CommonModule,
  DOCUMENT,
  Location,
  isPlatformBrowser,
  isPlatformServer,
} from '@angular/common';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
  RouterOutlet,
} from '@angular/router';
import { MetadataService } from './_services/metadata.service';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { AdventuresModule } from './adventures/adventures.module';
import { HomepageModule } from './homepage/homepage.module';
import { AgeConsentComponent } from './age-consent/age-consent.component';
import { CookieService } from 'ngx-cookie-service';
import { MatMenuModule } from '@angular/material/menu';
import { environment } from './environments/environment';
import { UsersService } from './_services/users.service';
import { NavigateToAccountService } from './_services/navigate-account.service';
import { LoaderService } from './_services/loader.service';
import { NgxLoadingModule } from 'ngx-loading';
import { filter } from 'rxjs';

const SHOW_AGE_CONSENT_KEY = makeStateKey<boolean>('showAgeConsent');
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    HttpClientModule,
    MatButtonModule,
    RouterModule,
    TranslocoModule,
    MatIconModule,
    AdventuresModule,
    HomepageModule,
    AgeConsentComponent,
    MatMenuModule,
    NgxLoadingModule,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  showAgeConsent: WritableSignal<boolean> = signal(false);

  public appPages = [
    { title: 'home', url: '', icon: 'home' },
    { title: 'favourites', url: '/favorites', icon: 'favorites' },
  ];

  public appExtraPages = [
    { title: 'terms', url: '/terms', icon: 'gavel' },
    {
      title: 'confidentiality',
      url: '/confidentiality',
      icon: 'assured_workload',
    },
    { title: 'openGuide', url: '/guide', icon: 'article' },
    { title: 'prices', url: '/prices', icon: 'monetization_on' },
  ];
  constructor(
    public metadataService: MetadataService,
    private _cookieService: CookieService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object,
    public navigateService: NavigateToAccountService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    public loaderService: LoaderService,
    @Inject(DOCUMENT) private _document: Document,
    private _renderer: Renderer2
  ) {}

  ngOnInit() {
    this.metadataService.apiUrl = environment.production
      ? this._document.location.origin + '/api'
      : 'https://adventures-dev.love69.eu/api';

    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((x) => {
        this.addCanonical();
      });

    this.metadataService.getMetadata().subscribe((res) => {
      this.metadataService.miscMetadata.set(res);
    });

    this.navigateService.getLanguage();
    this.navigateService.setParam();

    if (isPlatformServer(this.platformId)) {
      // Initialize showAgeConsent to false on the server
      this.showAgeConsent.set(false);

      // Transfer the initial state to the client
      this.transferState.set(SHOW_AGE_CONSENT_KEY, this.showAgeConsent());
    }

    // Check if the app is running on the client
    if (isPlatformBrowser(this.platformId)) {
      // Retrieve the initial state from the transfer state on the client
      const transferredState = this.transferState.get(
        SHOW_AGE_CONSENT_KEY,
        null
      );

      if (transferredState !== null) {
        // Use the transferred state
        this.showAgeConsent.set(transferredState);

        this._router.events.subscribe((x) => {
          if (x instanceof NavigationEnd) {
            const cookieValue = this._cookieService.get('age-consent');
            if (!cookieValue) this.openModal();
          }
        });
      }
    }
  }

  openModal() {
    this.showAgeConsent.set(true);
  }

  closeDialog() {
    this.showAgeConsent.set(false);
    this._cookieService.set('age-consent', 'yes', 2);
  }

  addCanonical() {
    const currentRoute = this._document.location.href;
    const existingCanonicalLink = this._document.head.querySelector('link[rel="canonical"]') as HTMLLinkElement; 

    if (!existingCanonicalLink) {
      const link = this._document.createElement('link') as HTMLLinkElement;

      link.href = currentRoute;
      link.rel = 'canonical';
      console.log(link);

      this._document.head.appendChild(link);
    } else {
      // Update the existing canonical link if the route has changed
      if (existingCanonicalLink.href !== currentRoute) {
        existingCanonicalLink.href = currentRoute;
        console.log(existingCanonicalLink);
      }
    }
  }
 
}
