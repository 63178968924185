import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-age-consent',
  standalone: true,
  imports: [CommonModule, TranslocoModule, MatButtonModule],
  templateUrl: './age-consent.component.html',
  styleUrl: './age-consent.component.scss'
})
export class AgeConsentComponent {
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(){}

  dismiss(){
    this.close.emit();
  }
}
