import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading, withViewTransitions } from '@angular/router';

import { routes } from './app.routes';
import {
  provideClientHydration,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideTransloco } from '@ngneat/transloco';
import { TranslocoHttpLoader } from './transloco-root.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MetadataService } from './_services/metadata.service';
import { CustomHttpService } from './_services/custom-http.service';
import { FilterService } from './_services/filters.service';
import { AdventuresModule } from './adventures/adventures.module';
import { NavigateToAccountService } from './_services/navigate-account.service';
import { NgxLoadingModule } from 'ngx-loading';

export const appConfig: ApplicationConfig = {
  providers: [
    AdventuresModule,
    {provide : MetadataService},
    {provide : CustomHttpService},
    {provide : FilterService},
    {provide : NavigateToAccountService },
    importProvidersFrom([NgxLoadingModule.forRoot({
      backdropBackgroundColour: 'rgba(0,0,0,0.8)',
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff',
    })]),
    provideRouter(routes, withViewTransitions(), withInMemoryScrolling({anchorScrolling : 'enabled',scrollPositionRestoration : 'enabled'})),
    provideClientHydration(
      withHttpTransferCacheOptions({
        includePostRequests: true,
      })
    ),
    importProvidersFrom([BrowserAnimationsModule]),
    provideHttpClient(withFetch()),
    provideTransloco({
      config: {
        availableLangs: ['en', 'ro'],
        defaultLang: 'ro',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
  ],
};
