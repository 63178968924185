import { Routes } from '@angular/router';
import { PricesComponent } from './prices/prices.component';
import { ProfileComponent } from './profile/profile.component';

export const routes: Routes = [
    {
        path : 'favorites',
        loadChildren: () => import('./favorites/favorites.module').then(m => m.FavoritesModule)
    },
    {
        path : 'prices',
        loadComponent : () => import('./prices/prices.component').then(m => m.PricesComponent)
    },
    {
        path : 'escorte',
        loadChildren: () => import('./adventures/adventures.module').then(m => m.AdventuresModule)
    },
    {
        path : 'terms',
        loadChildren: () => import('./terms/terms.module').then(m => m.TermsModule)
    },
    {
        path : 'confidentiality',
        loadChildren: () => import('./confidentiality/confidentiality.module').then(m => m.ConfidentialityModule)
    },
    {
        path : 'guide',
        loadChildren: () => import('./guide/guide.module').then(m => m.GuideModule)
    },
    {
        path : 'escorte/:country/:city/:id',
        loadComponent : () =>import('./profile/profile.component').then(m => m.ProfileComponent),
        pathMatch : 'full'
    },
    {
        path : '',
        loadChildren: () => import('./homepage/homepage.module').then(m => m.HomepageModule)
    },
    
];
